<template>
    <div style="background: #fff;margin-top:-16px;min-height: 300px;padding:20px;">
        <div>
            <div class="card-box">
                <a-card>
                    <div slot="title" style="display: flex;align-items: center;">
                        <img class="card-tit-img" src="https://qiniu.youjiamall.cn/crmNeed05.png" alt="">
                        <p class="card-tit">中标结果</p>
                    </div>
                    <div style="display: flex;justify-content: center">
                        <a-radio-group v-model="size" style="margin-bottom: 16px">
                            <a-radio-button :value="50">
                                待中标
                            </a-radio-button>
                            <a-radio-button v-if="needOrderDetail.status >= 50" :value="60">
                                已中标
                            </a-radio-button>
                            <a-radio-button v-if="needOrderDetail.status >= 50" :value="70">
                                未中标
                            </a-radio-button>
                        </a-radio-group>
                    </div>
                    <div style="display:flex;" v-if="size == 50">
                        <p
                            style="text-align:center;width:100%;height: 100px;line-height:100px;font-weight:500;font-size:16px">
                            需求已创建{{ createDay }}天</p>
                    </div>
                    <!-- 已中标 -->
                    <div style="display:flex" v-if="size == 60">
                        <div class="card-box-list" style="margin-right:10px;">
                            <div class="card-box-list-title">
                                <img src="https://qiniu.youjiamall.cn/crmNeedmini01.png" />
                                <span>中标信息</span>
                            </div>
                            <div class="card-box-list-main">
                                <div style="margin-right:60px;">
                                    <span class="card-box-list-mintit">合同份数</span>
                                    <p>
                                        <a-select default-value="2" style="width: 60px" v-model="form.compactCount"
                                            :disabled="isEdit">
                                            <a-select-option :value="2">2</a-select-option>
                                            <a-select-option :value="3">3</a-select-option>
                                            <a-select-option :value="4">4</a-select-option>
                                            <a-select-option :value="5">5</a-select-option>
                                            <a-select-option :value="6">6</a-select-option>
                                            <a-select-option :value="7">7</a-select-option>
                                            <a-select-option :value="8">8</a-select-option>
                                        </a-select>
                                        <!-- <span class="bigtext">{{ winResultData.compactCount }}</span> -->
                                        <span> 份</span>
                                        <!-- <a-icon type="edit" /> -->
                                    </p>

                                </div>
                                <div style="display:flex;flex-direction: column;">
                                    <p class="card-box-list-mintit">中标文件</p>
                                    <a-upload name="file" :multiple="false"
                                        v-if="!form.compactFileUrl && needOrderDetail.status != 120"
                                        :action="qiNiuAction" :data="postData" @change="uploadFile">
                                        <a-button>
                                            <a-icon type="upload" /> 上传文件
                                        </a-button>
                                    </a-upload>

                                    <div v-else style="width:120px;">
                                        <a :href="form.compactFileUrl" style="margin-left: 14px;"
                                            target="_blank">预览文件</a>
                                        <a @click="removeFile" v-if="!isEdit" style="margin-left: 5px;color:red;">删除</a>
                                    </div>
                                    <!-- <p style="margin-top:10px;">
                                    <a>查看文件<a-icon type="file" /></a>
                                </p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card-box-list" style="flex:1">
                            <div class="card-box-list-title">
                                <img src="https://qiniu.youjiamall.cn/crmNeedmini02.png" />
                                <span>初始价格信息</span>
                            </div>
                            <div class="card-box-list-main">
                                <div style="margin-right:60px;">
                                    <span class="card-box-list-mintit"
                                        v-if="needOrderDetail.subType === 102 || needOrderDetail.subType == 103">*产品预算/份</span>
                                    <span class="card-box-list-mintit"
                                        v-if="needOrderDetail.subType == 101">*产品预算</span>
                                    <p>
                                        <span class="bigtext">{{ winResultData.marketPrice.toFixed(2) }}</span>
                                        <span> 元</span>
                                    </p>
                                </div>
                                <!-- <div style="margin-right:60px;">
                                <span class="card-box-list-mintit">*单份预期产品成本</span>
                                <p>
                                    <span class="bigtext">1000.00</span>
                                    <span> 元</span>
                                </p>
                            </div> -->
                                <div style="margin-right:60px;" v-if="needOrderDetail.subType == 102">
                                    <span class="card-box-list-mintit">*采购数量</span>
                                    <p>
                                        <span class="bigtext">{{ winResultData.buyCount }}</span>
                                        <span> 份</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-box-list" style="flex:1">
                            <div class="card-box-list-title">
                                <img src="https://qiniu.youjiamall.cn/crmNeedmini03.png" />
                                <span style="border-bottom: 3px solid #5542f6;">最终价格信息</span>
                            </div>
                            <div class="card-box-list-main">
                                <div style="margin-right:60px;">
                                    <span class="card-box-list-mintit"
                                        v-if="needOrderDetail.subType === 102 || needOrderDetail.subType === 103">*产品预算/份</span>
                                    <span class="card-box-list-mintit"
                                        v-if="needOrderDetail.subType === 101">*产品预算</span>
                                    <p style="cursor: pointer;">
                                        <!-- <a-input style="width:100px;" :disabled="isEdit"
                                            v-model="form.lastMarketPrice"></a-input> -->
                                        <a-input-number :default-value="10" :formatter="value => `¥ ${value}`"
                                            style="width: 100px" :disabled="isEdit" v-model="form.lastMarketPrice"
                                            :min="0" :step="0.01" placeholder="请输入" />
                                        <span> 元 </span>
                                    </p>
                                </div>
                                <div style="margin-right:60px;"
                                    v-if="needOrderDetail.subType == 102 || needOrderDetail.subType === 103">
                                    <span class="card-box-list-mintit">*采购数量</span>
                                    <p style="cursor: pointer;">
                                        <a-input style="width:100px;" :disabled="isEdit"
                                            v-model="form.lastBuyCount"></a-input>
                                        <span> 份 </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="size == 70">
                        <a-textarea v-model="form.reason" :disabled="isEdit"
                            placeholder="请总结未中标原因，未下次积累宝贵经验"></a-textarea>
                        <div style="display: flex;padding: 20px 0px 0;">
                            <div style="margin-left: auto;"
                                v-if="size == 60 || size == 70 && needOrderDetail.status != 120">
                                <a-button v-if="!isEdit" type="primary" @click="WinResultConfirm">确认中标结果</a-button>
                                <a-button v-else type="primary" @click="isEdit = false">编辑中标结果</a-button>
                            </div>
                        </div>
                    </div>

                    <div style="display: flex;padding: 20px 0px 0;" v-if="needOrderDetail.status != 120">
                        <div style="margin-left: auto;"
                            v-if="size == 60 && (needOrderDetail.status == 50 || needOrderDetail.status == 60 || needOrderDetail.status == 70 || needOrderDetail.status == 100 || needOrderDetail.status == 90)">
                            <a-button v-if="!isEdit" type="primary" @click="WinResultConfirm">确认中标结果</a-button>
                            <a-button v-else type="primary" @click="isEdit = false">编辑中标结果</a-button>
                        </div>
                    </div>
                </a-card>
            </div>

            <!-- 选中已中标 大于已中标 不等于未中标 -->
            <div class="card-box" v-if="size == 60 && needOrderDetail.status >= 60 && needOrderDetail.status != 70">
                <a-card>
                    <div slot="title" style="display: flex;align-items: center;position:relative;">
                        <img class="card-tit-img" src="https://qiniu.youjiamall.cn/crmNeed06.png" alt="">
                        <p class="card-tit">商品信息</p>

                        <div v-if="needOrderDetail.status == 100 || needOrderDetail.status == 80 || needOrderDetail.status == 90"
                            style="background: #FFFBE6;border-radius: 4px;border: 1px solid #FFE58F;width:40%;height:70px;margin:auto;position:absolute;left:50%;transform: translateX(-50%);text-align:center;line-height:70px;font-size: 16px;">

                            <span v-if="needOrderDetail.status == 100"
                                style="color:red;font-size:14px;margin-left:10px"><a-icon type="info-circle"
                                    style="color:#FAAD14;font-size: 18px;margin-right: 5px;" /> 商品信息核验未通过：{{
                            needOrderDetail.reason
                        }}</span>
                            <span v-if="needOrderDetail.status == 80"
                                style="color:red;font-size:14px;margin-left:10px"><a-icon type="info-circle"
                                    style="color:#FAAD14;font-size: 18px;margin-right: 5px;" /> {{
                            needOrderDetail.statusDesc
                        }}</span>
                            <span v-if="needOrderDetail.status == 90"
                                style="color:red;font-size:14px;margin-left:10px"><a-icon type="info-circle"
                                    style="color:#FAAD14;font-size: 18px;margin-right: 5px;" /> {{
                            needOrderDetail.statusDesc
                        }}</span>
                        </div>
                    </div>
                    <!-- 1货堆 2宅配 -->
                    <div v-if="needOrderDetail.subType != 103">
                        <addressTable :type="needOrderDetail.subType == 101 ? 1 : 2"
                            :isEdit="needOrderDetail.status != 80 && winResultData.status == 50 || winResultData.status == 60 || winResultData.status == 70 || winResultData.status == 100"
                            :needOrderDetail="needOrderDetail" ref="addressRef" :needId="$route.query.id"
                            @sendAddress="sendAddress" @sendAddressType="sendAddressType">
                        </addressTable>
                    </div>
                    <!-- 方案列表 -->
                    <div style="min-height: 200px;margin-top:20px;">
                        <p class="title1">
                            方案信息
                            <a-button type="primary" style="margin-left: 10px;" size="small" @click="openSelectPlan"
                                v-if="winResultData.status == 50 || winResultData.status == 60 || winResultData.status == 70 || winResultData.status == 100">选择方案</a-button>
                            <!-- <span v-if="needOrderDetail.status == 100"
                                style="color:red;font-size:14px;margin-left:10px">商品信息核验未通过：{{ needOrderDetail.reason
                                }}</span>
                            <span v-if="needOrderDetail.status == 80"
                                style="color:red;font-size:14px;margin-left:10px">{{ needOrderDetail.statusDesc
                                }}</span>
                            <span v-if="needOrderDetail.status == 90"
                                style="color:green;font-size:14px;margin-left:10px">{{ needOrderDetail.statusDesc
                                }}</span> -->
                        </p>
                        <a-table style="margin-top:20px;" :columns="columns" :dataSource="dataSource" bordered
                            :pagination="false">
                            <template slot="modifyTime" slot-scope="record,text">
                                {{ formatDateMin(text.modifyTime) }}
                            </template>
                            <template slot="action" slot-scope="record,text">
                                <a v-if="text.type == 1" @click="openPreviewPlan(1, text)">预览</a>
                                <a v-if="text.type == 2" @click="openPreviewPlan(2, text)">下载</a>
                                <a v-if="text.type == 1" @click="DownLoad(text)" style="margin-left:10px">下载</a>
                                <!-- <a v-if="text.type == 1" @click="openPreviewPlan(1, text)">预览</a>
                                <a v-if="text.type == 2" @click="openPreviewPlan(2, text)">详情</a> -->
                                <!-- <a @click="DownLoad(text)" style="margin-left:10px">下载</a> -->
                            </template>
                        </a-table>
                        <div style="display: flex;justify-content: space-between;margin-top:20px;"
                            v-if="winResultData.status == 50 || winResultData.status == 60 || winResultData.status == 70 || winResultData.status == 100">
                            <a-button type="primary" style="margin-left: auto"
                                @click="submitProductInfo()">提交商品信息</a-button>
                        </div>
                    </div>
                </a-card>
            </div>

            <!-- 弹窗选择方案 -->
            <a-modal title="选择方案" :visible="openSelect" @cancel="cancelChange" :maskClosable="false" :footer="null"
                :width="1200" :z-index="100">
                <a-radio-group v-model="selectPlanType" @change="getPlanDraftList" :style="{ marginBottom: '8px' }">
                    <a-radio-button :value="50">确认方案</a-radio-button>
                    <a-radio-button :value="60">驳回方案</a-radio-button>
                </a-radio-group>

                <!-- <div v-for="(item, index) in selectPlanList" :key="index" style="margin-bottom:10px;"> -->
                <!-- <a-radio-group v-model="selectPlanIndex" @change="onChange" style="width:100%;"> -->
                <!-- <a-radio :value="index" style="margin-bottom:10px;">
                            {{ item.batchName }}
                        </a-radio> -->
                <!-- <div v-if="selectPlanList.length > 0"> -->
                <a-table :row-selection="rowSelection" :columns="planColumns" :dataSource="selectPlanList" bordered
                    :pagination="false">
                    <template slot="modifyTime" slot-scope="record,text">
                        {{ formatDateMin(text.modifyTime) }}
                    </template>
                </a-table>
                <!-- </div> -->
                <!-- </a-radio-group> -->
                <!-- </div> -->

                <div style="display: flex;;margin-top:20px;justify-content: right;">
                    <a-button @click="cancelChange">取消</a-button>
                    <a-button type="primary" style="margin-left: 10px" @click="selectConfirm">确认</a-button>
                </div>
            </a-modal>
            <a-modal title="方案详情" :visible="openPlanDetails" :maskClosable="false" :footer="null" :width="1200"
                @cancel="closeOpenPlanDetails" :z-index="100">
                <a-collapse v-model="activeKey" @change="openDetails" accordion>
                    <a-collapse-panel v-for="item in planDetailsDataSource" :key="item.id" :header="item.name">
                        <a-table :columns="innerColumns" :dataSource="innerData" bordered :pagination="false">
                            <template slot="type" slot-scope="record,text">
                                <span v-if="record == 1">单品</span>
                                <span v-if="record == 2">礼包-{{ text.name }}</span>
                            </template>
                            <template slot="calcPrice" slot-scope="record,text">
                                {{ getCalcPrice(text) }}
                            </template>
                            <template slot="plfPrice" slot-scope="record">
                                {{ record ? record.toFixed(2) : '暂无' }}
                            </template>
                            <template slot="platformDiscountPrice" slot-scope="record">
                                {{ record ? record.toFixed(2) : '暂无' }}
                            </template>
                        </a-table>
                    </a-collapse-panel>
                </a-collapse>
                <div class="page-box">
                    <a-pagination :total="planDetailsQueryTotal" :current="planDetailsQuery.page + 1"
                        :pageSize="planDetailsQuery.size" @change="handlePageChange" />
                </div>
            </a-modal>
        </div>

    </div>
</template>

<script>
import addressTable from '../../components/addressTable.vue';
import { request } from '@/utils/request'

export default {
    props: {
        needOrderDetail: {
            type: Object,
            default: null
        }
    },
    components: { addressTable },
    data() {
        return {
            size: 50,
            winResultData: {},
            editPrice: true,
            editCount: true,
            form: {
                compactCount: 2,
                lastMarketPrice: 0,
                lastBuyCount: 0
            },
            lastBuyCount: "",
            lastMarketPrice: "",
            planList: [],//方案列表
            selectPlanIndex: 0,//
            dataSource: [],
            columns: [
                { title: '方案名称', dataIndex: 'name', align: 'center' },
                { title: '方案类型', dataIndex: 'typeName', align: 'center' },
                { title: '方案提醒', dataIndex: 'remind', align: 'center' },
                { title: '方案设计', dataIndex: 'roleStr', align: 'center' },
                { title: '人员', dataIndex: 'staffName', align: 'center' },
                { title: '确认状态', dataIndex: 'statusName', align: 'center' },
                { title: '最后更新时间', dataIndex: 'modifyTime', align: 'center', scopedSlots: { customRender: 'modifyTime' } },
                { title: '操作', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ],
            openSelect: false,
            selectPlanType: 50, //1已使用 2未使用
            selectPlanList: [],
            planColumns: [
                { title: '方案名称', dataIndex: 'name', align: 'center' },
                { title: '方案类型', dataIndex: 'typeName', align: 'center' },
                { title: '方案提醒', dataIndex: 'remind', align: 'center' },
                { title: '方案设计', dataIndex: 'roleStr', align: 'center' },
                { title: '人员', dataIndex: 'staffName', align: 'center' },
                { title: '审核状态', dataIndex: 'statusName', align: 'center' },
                { title: '最后更新时间', dataIndex: 'modifyTime', align: 'center', scopedSlots: { customRender: 'modifyTime' } },
            ],
            selectedRowKeys: [],
            openPlanDetails: false,//方案详情弹窗
            innerColumns: [
                { title: '商品类型', dataIndex: 'typeName', align: 'center', },
                { title: '商品名称', dataIndex: 'name', align: 'center' },
                { title: '规格', dataIndex: 'specsAttributeValue', align: 'center' },
                { title: '数量', dataIndex: 'goodsSpecCount', align: 'center' },
                { title: '提示', dataIndex: 'remind', align: 'center' },
                { title: '市场建议零售价', dataIndex: 'plfPrice', align: 'center', scopedSlots: { customRender: 'plfPrice' } },
                { title: '优加底价', dataIndex: 'platformDiscountPrice', align: 'center', scopedSlots: { customRender: 'platformDiscountPrice' } },
                { title: '合计优加底价', dataIndex: 'calcPrice', align: 'center', scopedSlots: { customRender: 'calcPrice' } },
                { title: '供应商ID', dataIndex: 'supplierId', align: 'center' },
            ],
            // 方案详情数据
            planDetailsDataSource: [],
            // 方案详情参数
            planDetailsQuery: {
                page: 0,
                size: 10
            },
            planDetailsQueryTotal: 0,
            planIdList: [],//方案id
            qiNiuAction: 'https://upload-z1.qiniup.com',
            qiNiuUploadData: {
                key: '',
                token: ''
            },
            getQiniuTokenIng: false,
            postData: {
                token: ''
            },
            createDay: 0,
            activeKey: "",
            innerData: [],
            addressType: 1,
            addressBatchId: "",
            isEdit: false,
            goodsSelectedRowKeys: [],
            goodsSelectList: [],
            compactCount: "",

        }
    },
    mounted() {
        this.getPlanConfirmList();//查询用户已使用的
        this.getWinResult()
        this.getQiniuToken();
        this.getPlanDraftList()
        this.getPlanConfirmList()
        // this.getCreateDay();
        this.initAddress();
    },
    computed: {
        rowSelection() {
            return {
                selectedRowKeys: this.goodsSelectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.goodsSelectedRowKeys = selectedRowKeys; // Update selected row keys
                    this.goodsSelectList = selectedRows;
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                onSelect: (record, selected, selectedRows) => {
                    console.log(record, selected, selectedRows);
                    this.goodsSelectList = selectedRows;
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                    console.log(selected, selectedRows, changeRows);
                    this.goodsSelectList = selectedRows;
                },
            }
        }
    },
    watch: {
        needOrderDetail(newVal) {
            if (newVal) {
                this.addressType = newVal.addressType
                this.addressTypeInfo = newVal.addressTypeInfo
                this.getWinResult(1)
            }

        }
    },
    methods: {
        DownLoad(data) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/export', 'post', {
                planId: data.id,
                remarks: ""
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！请前往个人中心下载！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 合计优加底价
        getCalcPrice(text) {
            if (text.platformDiscountPrice) {
                return (text.platformDiscountPrice * text.goodsSpecCount).toFixed(2);
            } else {
                return "暂无"
            }

        },
        // 查询提交商品信息列表方案
        getPlanConfirmList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/planList', 'post', { needOrderDetailId: this.$route.query.id, useStatus: 1, }).then(res => {
                this.dataSource = res.data.data[0].needOrderPlanVoList
            })
        },
        closeOpenPlanDetails() {
            this.openPlanDetails = false;
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return result;
        },
        sendAddress(data) {
            if (this.addressType == 3) {
                this.addressTypeInfo = data;
            } else {
                this.addressBatchId = data;
            }
        },
        sendAddressType(e) {
            this.addressType = e;
            console.log(e, 'e')
        },
        // getCreateDay() {
        //     request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/winResultDetail/' + this.$route.query.id, 'post', {}).then((res) => {
        //         console.log(res, '---')
        //         this.createDay = res.data.data.createDay;
        //     })
        // },
        initAddress() {
            this.planExceptTimeValue = ""
            this.$refs.addressRef.initAddress();
            this.$refs.addressRef.getAddressList();
        },
        uploadFile(info) {
            const { fileList } = info;
            console.log(fileList, 'fileList')
            if (fileList.length > 0 && fileList[0].status === 'done') {
                this.$set(this.form, 'compactFileUrl', fileList[0].response.url);
            }
        },
        removeFile() {
            this.$set(this.form, 'compactFileUrl', "");
            delete this.form.compactFileUrl
        },
        getQiniuToken() {
            if (!this.getQiniuTokenIng) {
                this.getQiniuTokenIng = true
                this.loading = true
                this.loadingText = 'token加载中'
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/qiniu/token/yjhp-hb', 'get').then((res) => {
                    if (res.data.code == 200) {
                        this.postData.token = res.data.data
                        this.getQiniuTokenIng = false
                    }
                })
            }
        },
        // 提交商品信息
        submitProductInfo() {
            if (this.needOrderDetail.subType != 103) {
                if (this.$refs.addressRef.tabLoading) {
                    this.$message.error('数据正在导入中，请稍后再试！')
                    return false;
                }
            }
            if (this.dataSource.length == 0) {
                this.$message.error('请选择方案！')
                return false;
            }
            let planIdList = []
            if (this.selectPlanList.length == 0) {
                this.selectPlanList = this.dataSource
            }
            this.dataSource.forEach(item => {
                planIdList.push(item.id)
            })
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/productInfoSubmit', 'post', {
                id: this.$route.query.id,//子需求单id
                addressType: this.addressType,
                addressTypeInfo: this.addressTypeInfo,
                addressBatchId: this.addressBatchId,
                planIdList: planIdList,//方案ID
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功！')
                    this.getWinResult()

                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 打开预览方案弹窗
        openPreviewPlan(type, text) {
            console.log(type, text)
            this.innerData = {}; // 重置内层数据，确保每次打开时子级表格折叠
            if (type == 2) {
                window.open(text.fileUrl)
            } else {
                this.openPlanDetails = true;
                this.planDetailsQuery.needOrderDetailId = this.$route.query.id //子需求id
                this.planDetailsQuery.planId = text.id; //需求单id
                this.getPlanDetailsList()
            }
        },
        openDetails(e) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/v2/list/' + e, 'post', {}).then(res => {
                this.innerData = res.data.data;
            })
        },
        // 获取方案详情
        getPlanDetailsList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/page', 'post', this.planDetailsQuery).then(res => {
                this.planDetailsDataSource = res.data.data.data;
                this.planDetailsQuery.page = res.data.data.page;
                this.planDetailsQuery.size = res.data.data.size;
                this.planDetailsQueryTotal = res.data.data.total;
            })
        },
        handlePageChange(page) {
            this.planDetailsQuery.page = page - 1;
            this.getPlanDetailsList()
        },

        // 确认中标结果
        WinResultConfirm() {
            let that = this;
            if (that.size == 60) {
                if (!that.form.lastMarketPrice && this.needOrderDetail.subType == 102) {
                    that.$message.error('请输入最终产品预算/份！')
                    return false;
                }
                if (this.needOrderDetail.subType == 101) {
                    if (!that.form.lastMarketPrice) {
                        that.$message.error('请输入最终产品预算！')
                        return false;
                    }

                }
                // if (!that.form.lastBuyCount && that.needOrderDetail.subType == 102) {
                if (that.needOrderDetail.subType == 102 || this.needOrderDetail.subType == 103) {
                    if (!that.form.lastBuyCount) {
                        that.$message.error('请输入采购数量！')
                        return false;
                    }
                }
                // if (!that.form.compactFileUrl) {
                //     that.$message.error('请上传中标文件！')
                //     return false;
                // }
            }
            if (that.size == 70) {
                if (!that.form.reason) {
                    that.$message.error('请输入原因！')
                    return false;
                }
            }
            this.$confirm({
                title: '您确定要执行该操作吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/winResultConfirm', 'post', {
                        id: that.$route.query.id,// 子需求单id
                        status: that.size,//子需求单状态
                        compactCount: that.form.compactCount,//合同份数
                        compactFileUrl: that.form.compactFileUrl, //中标文件
                        lastMarketPrice: that.form.lastMarketPrice,//产品售价
                        lastBuyCount: that.form.lastBuyCount,//采购数量
                        reason: that.form.reason,//原因

                    }).then(res => {
                        if (res.data.code == 200) {
                            that.$message.success('操作成功！')
                            that.isEdit = true;
                            that.getWinResult()
                            that.getQiniuToken();
                            that.initAddress();
                            that.getPlanConfirmList()
                        } else {
                            that.$message.error(res.data.message)
                        }
                    })

                    // 需要刷新页面
                }
            });

        },
        // 中标结果详情
        getWinResult(type) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/winResultDetail/' + this.$route.query.id, 'post', {}).then(res => {
                this.editPrice = false
                this.editCount = false;
                this.createDay = res.data.data.createDay;
                if (res.data.data.snapshotStatus) {
                    this.size = res.data.data.snapshotStatus
                }
                if (res.data.data.status < 50) {
                    this.size = 50
                }
                this.winResultData = res.data.data;
                this.form.compactCount = res.data.data.compactCount;
                this.form.lastMarketPrice = res.data.data.lastMarketPrice;
                this.form.compactFileUrl = res.data.data.compactFileUrl;
                this.form.lastBuyCount = res.data.data.lastBuyCount;
                this.form.reason = res.data.data.reason
                if (this.form.lastBuyCount || this.form.compactCount || this.form.reason) {
                    this.isEdit = true;
                }
                if (!type) {
                    this.$emit('reload')
                }
            })
        },
        // 打开选择方案
        openSelectPlan() {
            this.openSelect = true;
            this.getPlanDraftList()
        },
        cancelChange() {
            this.goodsSelectedRowKeys = []
            this.openSelect = false;
        },
        // 获取选择方案列表
        getPlanDraftList() {
            this.goodsSelectedRowKeys = []
            // if (this.selectPlanType == 1) {
            this.selectPlanList = [];
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/planList', 'post', { statusList: [this.selectPlanType], needOrderDetailId: this.$route.query.id }).then(res => {
                console.log(res, 'res---')
                res.data.data.forEach(item => {
                    console.log(item, 'item')
                    item.needOrderPlanVoList.forEach(it => {
                        this.selectPlanList.push(it)
                        console.log(this.selectPlanList, 'this.selectPlanList')
                    })
                })
            })
            console.log(this.selectPlanList, 'this.selectPlanList')
            // }
        },
        // 确认选择方案
        selectConfirm() {
            console.log(this.goodsSelectList, 'goodsSelectList')
            console.log(this.selectPlanIndex, 'selectPlanIndex')
            this.dataSource = this.goodsSelectList
            // this.dataSource = this.selectPlanList[this.selectPlanIndex].needOrderPlanVoList
            this.openSelect = false;
        },
        // 选择方案
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
    }
}
</script>


<style scoped>
.card-box {
    border: 1px solid #f0f0f0;
    margin-top: 20px;
}

::v-deep .ant-card-head {
    background: #FAFAFA;
    padding: 0 20px;
}

.remarks {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}


::v-deep .ant-table-thead>tr>th {
    background: #fff !important;
}

p {
    margin: 0;
    padding: 0;
}

.card-tit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-left: 10px;
}

.card-tit-img {
    width: 38px;
    height: 38px;
    border-radius: 100%
}


.card-tit-img {
    width: 38px;
    height: 38px;
    border-radius: 100%
}


.card-box-list {
    padding: 24px 0;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E8E8E8;
    padding: 26px;
    /* width: 288px; */
}

.card-box-list-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
}

.card-box-list-title img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 16px;
}

.card-box-list-mintit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    margin: 12px 0;
    display: inline-block;
}


.card-box-list-main {
    display: flex;
    flex-wrap: wrap;
    padding-left: 40px;
}

.bigtext {
    font-family: HelveticaNeue, HelveticaNeue;
    font-weight: 500;
    font-size: 24px;
    color: #010101;
}

.title1 {
    padding: 0;
    margin: 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #010101;
}



.page-box {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}
</style>