<template>
    <div style="background: #fff;margin-top:-16px;min-height: 300px;padding:20px;">
        <div class="card-box">
            <a-card>
                <div slot="title" style="display: flex;align-items: center;">
                    <img class="card-tit-img" src="https://qiniu.youjiamall.cn/crmNeed02.png" alt="">
                    <p class="card-tit">方案设计（{{ needOrderDetail.planTypeDesc }}）</p>
                </div>

                <div style="text-align: center">
                    <!-- 采购设计 -->
                    <p v-if="needOrderDetail.planType == 1 && needOrderDetail.status == 20"
                        style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 16px;color: #010101;margin-bottom: 10px;">
                        等待采购审核...
                    </p>
                    <p v-if="needOrderDetail.planType == 1 && needOrderDetail.status == 30 && !needOrderDetail.planFirstTime"
                        style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 16px;color: #010101;margin-bottom: 10px;">
                        等待采购设计方案...
                    </p>
                    <!-- <p v-if="needOrderDetail.planType == 1 && needOrderDetail.status < 20"
                        style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 16px;color: #010101;margin-bottom: 10px;">
                        等待采购设计方案...</p> -->
                    <!-- 销售设计 -->
                    <a-button type="primary" @click="toScheme(0)"
                        v-if="needOrderDetail.planType == 2 && isPlanUploadBtn">方案设计</a-button>
                    <div v-if="needOrderDetail.planFirstTime"
                        style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 16px;color: #010101;height:50px;line-height: 50px;text-align: center">
                        方案设计第一次上传时间为：{{ needOrderDetail.planFirstTime }}
                    </div>
                    <div v-if="needOrderDetail.planDueFinishTime"
                        style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 16px;color: #010101;height:50px;line-height: 50px;text-align: center">
                        方案预计完成时间：{{ needOrderDetail.planDueFinishTime }}
                    </div>
                    <a-table style="margin-top:10px" v-if="needOrderDetail.planType == 2 && planDraftList.length > 0"
                        :columns="columnsTest" :dataSource="planDraftList" bordered :pagination="false">
                        <template slot="action" slot-scope="record,text">
                            <a @click="toScheme(1, text)">编辑</a>
                        </template>
                        <template slot="modifyTime" slot-scope="record,text">
                            {{ formatDateMin(text.modifyTime) }}
                        </template>
                    </a-table>

                    <div style="display: flex;padding:20px 0 0 0;justify-content: right;"
                        v-if="needOrderDetail.status != 120">
                        <a-button type="primary" v-if="needOrderDetail.planType == 2 && planDraftList.length > 0"
                            style="margin-left: 10px" @click="changeStatusConfirm(30)">提交方案信息</a-button>
                    </div>
                </div>


            </a-card>
        </div>
        <div class="card-box" v-if="needOrderDetail.planType == 1">
            <a-card>
                <div slot="title" style="display: flex;align-items: center;">
                    <img class="card-tit-img" src="https://qiniu.youjiamall.cn/crmNeed03.png" alt="">
                    <p class="card-tit">销售确认</p>
                </div>
                <!-- <span slot="extra" v-if="needOrderDetail.planDueFinishTime"> 方案预计完成时间：{{ needOrderDetail.planDueFinishTime }}</span> -->
                <div v-if="needOrderDetail.planType == 1 && planList.length > 0">
                    <div v-for="(item, index) in planList" :key="index" style="margin-bottom:10px;">
                        <a-radio-group v-model="selectPlanIndex" style="width:100%;">
                            <a-radio :value="index" style="margin-bottom:10px;">
                                {{ item.batchName }}
                            </a-radio>
                            <div v-if="item.needOrderPlanVoList">
                                <a-table :columns="columns" :dataSource="item.needOrderPlanVoList" bordered
                                    :pagination="false">
                                    <template slot="status" slot-scope="record,text">
                                        <a>
                                            {{ text.statusName }}
                                            <a-tooltip>
                                                <template slot="title">
                                                    {{ text.reason }}
                                                </template>
                                                <a-icon v-if="text.status == 40" style="color:red;"
                                                    type="exclamation-circle" />
                                            </a-tooltip>
                                        </a>
                                    </template>
                                    <template slot="action" slot-scope="record,text">
                                        <a v-if="text.type == 1" @click="openXSPreviewPlan(1, text)">预览</a>
                                        <a v-if="text.type == 2" @click="openXSPreviewPlan(2, text)">下载</a>
                                        <a v-if="text.type == 1" @click="DownLoad(text)" style="margin-left:10px">下载</a>
                                    </template>
                                    <template slot="modifyTime" slot-scope="record,text">
                                        {{ formatDateMin(text.modifyTime) }}
                                    </template>
                                </a-table>
                            </div>

                        </a-radio-group>
                    </div>
                </div>
            </a-card>
            <div style="display: flex;padding:0 20px 20px;" v-if="planList.length > 0 && needOrderDetail.status != 120">
                <div style="margin-left: auto;" v-if="planList[selectPlanIndex].needOrderPlanVoList[0].status == 20">
                    <a-button type="danger" @click="changeStatusNo(40)">销售驳回方案</a-button>
                    <a-button type="primary" style="margin-left: 10px" @click="changeStatus(30)">销售确认方案</a-button>
                </div>
            </div>
        </div>
        <div class="card-box">
            <a-card>
                <div slot="title" style="display: flex;align-items: center;">
                    <img class="card-tit-img" src="https://qiniu.youjiamall.cn/crmNeed04.png" alt="">
                    <p class="card-tit">客户确认</p>
                </div>
                <div v-for="(item, index) in userPlanList" :key="index" style="margin-bottom:10px;">
                    <a-radio-group v-model="userSelectPlanIndex" style="width:100%;">
                        <a-radio :value="index" style="margin-bottom:10px;">
                            {{ item.batchName }}
                        </a-radio>
                        <a-table :columns="columns" :dataSource="item.needOrderPlanVoList" bordered :pagination="false">
                            <template slot="modifyTime" slot-scope="record,text">
                                {{ formatDateMin(text.modifyTime) }}
                            </template>
                            <template slot="status" slot-scope="record,text">
                                <a>
                                    {{ text.statusName }}
                                    <a-tooltip>
                                        <template slot="title">
                                            {{ text.reason }}
                                        </template>
                                        <a-icon v-if="text.status == 60" style="color:red;" type="exclamation-circle" />
                                    </a-tooltip>
                                </a>
                            </template>
                            <template slot="action" slot-scope="record,text">
                                <a v-if="text.type == 1" @click="openXSPreviewPlan(1, text)">预览</a>
                                <a v-if="text.type == 2" @click="openXSPreviewPlan(2, text)">下载</a>
                                <a v-if="text.type == 1" @click="DownLoad(text)" style="margin-left:10px">下载</a>
                                <!-- <a v-if="text.type == 1" @click="openPreviewPlan(1, text)">预览</a>
                                <a v-if="text.type == 2" @click="openPreviewPlan(2, text)">详情</a>
                                <a @click="DownLoad(text)" style="margin-left:10px">下载</a> -->
                                <!-- <a v-if="text.status == 60 && isPlanUploadBtn" style="margin-left:10px" @click="toScheme(1, text)">编辑</a> -->
                                <a v-if="text.status == 60 && isPlanUploadBtn" style="margin-left:10px"
                                    @click="copyPlan(text)">复制</a>
                            </template>
                        </a-table>
                    </a-radio-group>
                </div>
            </a-card>
            <div v-if="userPlanList.length > 0 && needOrderDetail.status != 120"
                style="display: flex;padding:0 20px 20px;">
                <div style="margin-left: auto;">
                    <a-button type="danger"
                        v-if="userPlanList[userSelectPlanIndex].needOrderPlanVoList[0].status == 30 || userPlanList[userSelectPlanIndex].needOrderPlanVoList[0].status == 50"
                        @click="changeStatusNo(60)">客户驳回方案</a-button>
                    <a-button type="primary"
                        v-if="userPlanList[userSelectPlanIndex].needOrderPlanVoList[0].status == 30"
                        style="margin-left: 10px" @click="changeStatus(50)">客户确认方案</a-button>
                </div>
            </div>
        </div>

        <!-- 弹窗方案详情 -->
        <a-modal title="方案详情" :visible="openPlanDetails" :maskClosable="false" :footer="null" :width="1200"
            @cancel="closeOpenPlanDetails" :z-index="100">
            <a-collapse v-model="activeKey" @change="openDetails" accordion>
                <a-collapse-panel v-for="item in planDetailsDataSource" :key="item.id" :header="item.name">
                    <a-table :columns="innerColumns" :dataSource="innerData" bordered :pagination="false">

                        <template slot="type" slot-scope="record,text">
                            <span v-if="record == 1">单品</span>
                            <span v-if="record == 2">礼包-{{ text.name }}</span>
                        </template>
                        <template slot="calcPrice" slot-scope="record,text">
                            {{ getCalcPrice(text) }}
                        </template>
                        <template slot="plfPrice" slot-scope="record">
                            {{ record ? record.toFixed(2) : '暂无' }}
                        </template>
                        <template slot="platformDiscountPrice" slot-scope="record">
                            {{ record ? record.toFixed(2) : '暂无' }}
                        </template>
                    </a-table>
                </a-collapse-panel>
            </a-collapse>
            <div class="page-box">
                <a-pagination :total="planDetailsQueryTotal" :current="planDetailsQuery.page + 1"
                    :pageSize="planDetailsQuery.size" @change="handlePageChange" />
            </div>
        </a-modal>

        <a-modal title="驳回原因" :visible="openNo" :maskClosable="false" @ok="reasonOk" :width="500"
            @cancel="openNo = false; reason = null" :z-index="100">
            <a-textarea v-model="reason"></a-textarea>
        </a-modal>

    </div>
</template>

<script>
import { request } from '@/utils/request'
export default {
    props: {
        needOrderDetail: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            dataSource: [],
            columns: [
                { title: '方案名称', dataIndex: 'name', align: 'center', width: 200 },
                { title: '方案类型', dataIndex: 'typeName', align: 'center' },
                { title: '方案提醒', dataIndex: 'remind', align: 'center', },
                { title: '方案设计', dataIndex: 'roleStr', align: 'center' },
                { title: '人员', dataIndex: 'staffName', align: 'center', },
                { title: '确认状态', dataIndex: 'status', align: 'center', scopedSlots: { customRender: 'status' }, },
                // 
                { title: '最后更新时间', dataIndex: 'modifyTime', align: 'center', scopedSlots: { customRender: 'modifyTime' }, },
                { title: '操作', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ],
            columnsTest: [
                { title: '方案名称', dataIndex: 'name', align: 'center', width: 200 },
                { title: '方案类型', dataIndex: 'typeName', align: 'center', width: 100 },
                { title: '方案提醒', dataIndex: 'remind', align: 'center', width: 100 },
                { title: '方案设计', dataIndex: 'roleStr', align: 'center', width: 100 },
                { title: '人员', dataIndex: 'staffName', align: 'center', width: 100 },
                { title: '最后更新时间', dataIndex: 'modifyTime', align: 'center', scopedSlots: { customRender: 'modifyTime' }, width: 100 },
                { title: '操作', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ],
            isPlanUploadBtn: false,
            planList: [],//方案列表
            selectPlanIndex: 0,//选中第几个版本
            selectIdList: [],//方案id
            userSelectPlanIndex: 0,
            planDraftList: [],//方案草稿
            openPlanDetails: false,//方案详情弹窗
            // 方案列表表头
            planDetailsColumns: [
                { title: '组合', dataIndex: 'name', align: 'center', width: 200 },
            ],
            // 方案详情数据
            planDetailsDataSource: [],
            // 方案详情参数
            planDetailsQuery: {
                page: 0,
                size: 10
            },
            planDetailsQueryTotal: 0,
            userPlanList: [],
            innerColumns: [
                { title: '商品类型', dataIndex: 'typeName', align: 'center'},
                { title: '商品名称', dataIndex: 'name', align: 'center' },
                { title: '规格', dataIndex: 'specsAttributeValue', align: 'center' },
                { title: '数量', dataIndex: 'goodsSpecCount', align: 'center' },
                { title: '提示', dataIndex: 'remind', align: 'center' },
                // { title: '销售售卖价', dataIndex: 'plfPrice', align: 'center' },
                { title: '市场建议零售价', dataIndex: 'plfPrice', align: 'center' ,scopedSlots: {customRender:'plfPrice'}},
                { title: '优加底价', dataIndex: 'platformDiscountPrice', align: 'center', scopedSlots: { customRender: 'platformDiscountPrice' } },
                { title: '合计优加底价', dataIndex: 'calcPrice', align: 'center', scopedSlots: { customRender: 'calcPrice' } },
                { title: '供应商ID', dataIndex: 'supplierId', align: 'center' },
            ],
            innerData: [], // 存储子级数据
            activeKey: "",
            openNo: false,
            reason: "",
            reasonType: "",

        }
    },
    watch: {
        $route() {
            if (this.$route.query.id) {
                // 查询是否显示方案设计按钮
                this.getPlanUploadLimit();
                // 查询方案列表
                this.getPlanList();
                // 查方案草稿
                this.getPlanDraftList()
            }
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        DownLoad(data) {
            // if(data.type ==2) {
            //     window.open(data.fileUrl)
            // } else {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/export', 'post', {
                planId: data.id,
                remarks: ""
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！请前往个人中心下载！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
            // }

        },

        // 合计优加底价
        getCalcPrice(text) {
            if (text.platformDiscountPrice) {
                return (text.platformDiscountPrice * text.goodsSpecCount).toFixed(2);
            } else {
                return "暂无"
            }
        },
        openDetails(e) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/v2/list/' + e, 'post', {}).then(res => {
                console.log(res, 'resss')
                this.innerData = res.data.data;
            })
        },
        closeOpenPlanDetails() {
            this.openPlanDetails = false;
        },
        init() {
            // 查询是否显示方案设计按钮
            this.getPlanUploadLimit();
            // 查询方案列表
            this.getPlanList();
            // 查方案草稿
            this.getPlanDraftList()
        },
        changeStatusConfirm(type) {
            let ids = []
            if (this.planDraftList.length > 0) {
                this.planDraftList.forEach(item => {
                    ids.push(item.id)
                })
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/updateStatus', 'post', {
                    idList: ids,
                    status: type,
                    reason: this.reason,
                }).then(res => {
                    console.log(res, 'res')
                    this.init()
                })
            }

        },
        changeStatusNo(type) {
            this.reasonType = type;
            this.openNo = true;
        },
        reasonOk() {
            if (!this.reason) {
                this.$message.warning('请输入驳回原因！')
                return false;
            }
            this.selectIdList = []
            // 获取方案中的id
            if (this.reasonType == 40) {
                this.planList[this.selectPlanIndex].needOrderPlanVoList.forEach(it => {
                    this.selectIdList.push(it.id)
                })
            }
            // 客户驳回
            if (this.reasonType == 60) {
                this.userPlanList[this.userSelectPlanIndex].needOrderPlanVoList.forEach(it => {
                    this.selectIdList.push(it.id)
                })
            }

            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/updateStatus', 'post', {
                status: this.reasonType,
                reason: this.reason,
                idList: this.selectIdList //方案id
            }).then(res => {
                if (res.data.code == 200) {
                    this.reasonType = ""
                    this.reason = ""
                    this.openNo = false;
                    this.$message.success('操作成功！')
                    // 需要刷新页面
                    // 查询是否显示方案设计按钮
                    this.getPlanUploadLimit();
                    // 查询方案列表
                    this.getPlanList();
                    // 查方案草稿
                    this.getPlanDraftList()
                    // s刷新父组件
                    this.$emit('reload')
                } else {
                    this.$message.error(res.data.message)
                }
            })

        },
        // 修改需求单状态
        changeStatus(type) {
            let that = this;
            this.$confirm({
                title: '您确定要执行该操作吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    if (type == 0) {
                        //取消需求单
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/cancel/' + that.$route.query.id, 'post', {}).then(res => {
                            if (res.data.code == 200) {
                                // 查询是否显示方案设计按钮
                                that.getPlanUploadLimit();
                                // 查询方案列表
                                that.getPlanList();
                                // 查方案草稿
                                that.getPlanDraftList()
                                // s刷新父组件
                                that.$emit('reload')
                            }
                        })
                    } else {
                        that.selectIdList = []
                        // 客户确认/驳回
                        if (type == 50 || type == 60) {
                            // 获取方案中的id
                            that.userPlanList[that.userSelectPlanIndex].needOrderPlanVoList.forEach(it => {
                                that.selectIdList.push(it.id)
                            })

                        } else {
                            // 获取方案中的id
                            that.planList[that.selectPlanIndex].needOrderPlanVoList.forEach(it => {
                                that.selectIdList.push(it.id)
                            })
                        }
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/updateStatus', 'post', {
                            status: type,
                            reason: "",
                            idList: that.selectIdList //方案id
                        }).then(res => {
                            if (res.data.code == 200) {
                                that.$message.success('操作成功！')
                                // 需要刷新页面
                                // 查询是否显示方案设计按钮
                                that.getPlanUploadLimit();
                                // 查询方案列表
                                that.getPlanList();
                                // 查方案草稿
                                that.getPlanDraftList()
                                // s刷新父组件
                                that.$emit('reload')
                            } else {
                                that.$message.error(res.data.message)
                            }
                        })
                    }

                }
            });

        },
        // 获取当前是否展示设计方案按钮
        getPlanUploadLimit() {
            if (this.$route.query.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/planUploadLimit/' + this.$route.query.id, 'get').then(res => {
                    if (res.data.data == 0) {
                        this.isPlanUploadBtn = false;
                    } else {
                        this.isPlanUploadBtn = true;
                    }
                    // isPlanUploadBtn
                })
            }



        },
        // 查设计好的方案草稿
        getPlanDraftList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/planDraftList', 'post', { needOrderDetailId: this.$route.query.id, statusList: [10] }).then(res => {
                this.planDraftList = res.data.data;
            })
        },
        // 查询客户/销售确认方案
        getPlanList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/planList', 'post', { needOrderDetailId: this.$route.query.id, statusList: [20, 40] }).then(res => {
                console.log(res, 'getPlanList')
                this.planList = res.data.data;
            })
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/planList', 'post', { needOrderDetailId: this.$route.query.id, statusList: [30, 50, 60] }).then(res => {
                console.log(res, 'getPlanList')
                this.userPlanList = res.data.data;
            })
        },
        // 复制方案
        copyPlan(text) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/copy', 'post', { needOrderDetailId: this.$route.query.id, needOrderId: this.$route.query.needId, planId: text.id }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('复制成功！')
                    this.getPlanDraftList()
                }
            })
        },
        toScheme(type, text) {
            if (type == 1) {
                this.$router.push({
                    path: '/sfa_demandList/sfa_demand_scheme',
                    query: {
                        planId: text.id,
                        subType: this.needOrderDetail.subType,
                        needId: this.$route.query.needId,
                        needOrderDetailId: this.$route.query.id,
                        planName: text.name,
                        planStatus: text.status
                    }
                })
            } else {
                this.$router.push({
                    path: '/sfa_demandList/sfa_demand_scheme',
                    query: {
                        subType: this.needOrderDetail.subType,
                        needId: this.$route.query.needId,
                        needOrderDetailId: this.$route.query.id,
                        // planStatus: text.status
                    }
                })
            }

        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return result;
        },
        // 打开预览方案弹窗
        openPreviewPlan(type, text) {
            if (type == 2) {
                window.open(text.fileUrl)
            } else {
                this.openPlanDetails = true;
                this.planDetailsQuery.needOrderDetailId = this.$route.query.id //子需求id
                this.planDetailsQuery.planId = text.id; //需求单id
                this.getPlanDetailsList()
            }
        },
        openXSPreviewPlan(type, text) {
            if (type == 2) {
                window.open(text.fileUrl)
            } else {
                this.openPlanDetails = true;
                this.planDetailsQuery.needOrderDetailId = this.$route.query.id //子需求id
                this.planDetailsQuery.planId = text.id; //需求单id
                this.getPlanDetailsList()
            }
        },
        // 获取方案详情
        getPlanDetailsList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/page', 'post', this.planDetailsQuery).then(res => {
                this.planDetailsDataSource = res.data.data.data;
                this.planDetailsQuery.page = res.data.data.page;
                this.planDetailsQuery.size = res.data.data.size;
                this.planDetailsQueryTotal = res.data.data.total;
            })
        },
        handlePageChange(page) {
            this.planDetailsQuery.page = page - 1;
            this.getPlanDetailsList()
        },
    }
}
</script>


<style scoped>
.card-box {
    border: 1px solid #f0f0f0;
    margin-top: 20px;
}

::v-deep .ant-card-head {
    background: #FAFAFA;
    padding: 0 20px;
}

.remarks {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}


::v-deep .ant-table-thead>tr>th {
    background: #fff !important;
}

p {
    margin: 0;
    padding: 0;
}

.card-tit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-left: 10px;
}

.card-tit-img {
    width: 38px;
    height: 38px;
    border-radius: 100%
}


.card-tit-img {
    width: 38px;
    height: 38px;
    border-radius: 100%
}


.card-box-list {
    padding: 24px 0;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E8E8E8;
    padding: 26px;
    /* width: 288px; */
}

.card-box-list-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
}

.card-box-list-title img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 16px;
}

.card-box-list-mintit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    margin: 12px 0;
    display: inline-block;
}


.card-box-list-main {
    display: flex;
    flex-wrap: wrap;
    padding-left: 40px;
}

.bigtext {
    font-family: HelveticaNeue, HelveticaNeue;
    font-weight: 500;
    font-size: 24px;
    color: #010101;
}



.page-box {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}
</style>